import styled, { css } from 'styled-components'

interface SideNavProps {
  isOpen: boolean
}

const openStyles = css`
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
`

const closedStyles = css`
  height: 0;
`

const SideNav = styled.div<SideNavProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => (props.isOpen ? openStyles : closedStyles)}
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
`
export default SideNav
