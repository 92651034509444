import * as React from 'react'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps
} from 'styled-system'
import Title from 'src/components/Title'
import Img, { GatsbyImageFluidProps } from 'gatsby-image'
import { heights } from '../styles/variables'
import { AppTheme, getColor, getFontWeight } from '../styles/theme'
import { useScreenSize } from 'src/hooks/useScreenSize'
import { Burger } from './Burger'
import { useEventListener } from 'src/hooks/useEventListener'
import { useState } from 'react'
import SideNav from './SideNav'
import { ContentWrapper } from './ContentWrapper'
import Paragraph from './Paragraph'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { graphql, useStaticQuery } from 'gatsby'
import { useDataContext } from 'src/contexts/dataContext'
import { LogoTop } from './Logo'

interface StyledHeaderProps {
  isMobile: boolean
  fullNavbar: boolean
}

const StyledHeader = styled.header<StyledHeaderProps>`
  @keyframes navbar-focus-in {
    0% {
      -webkit-filter: blur(10px);
      filter: blur(10px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }

  -webkit-animation: ${props =>
    props.isMobile
      ? ''
      : 'navbar-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both'};
  animation: ${props =>
    props.isMobile
      ? ''
      : 'navbar-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both'};
  display: flex;
  justify-content: ${props =>
    props.isMobile ? 'space-between' : 'space-around'};
  align-items: center;
  height: ${heights.header}px;
  background-color: ${props =>
    props.fullNavbar || props.isMobile ? 'white' : 'transparent'};
  position: ${props =>
    props.fullNavbar || props.isMobile ? 'fixed' : 'absolute'};
  z-index: 101;
  width: ${props => (props.fullNavbar || props.isMobile ? '100%' : '80%')};
  border-bottom: ${props => (props.isMobile ? '' : `2px solid #8d363f`)};
`

interface HeaderItemsContainerProps {
  fullNavbar: boolean
  isMobile: boolean
}

const HeaderItemsContainer = styled.div<HeaderItemsContainerProps>`
  display: flex;
  justify-content: center;
  margin: ${props => (props.fullNavbar || props.isMobile ? '2rem' : '0')};
  align-items: center;
`

interface LinkProps {
  isMobile: boolean
}

type Lprops = LinkProps & SpaceProps<AppTheme>
const Link = styled.a<Lprops>`
  color: ${props => (props.isMobile ? '#8d363f' : '#8d363f')};
  font-weight: ${getFontWeight('bold')};
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${heights.header - 2}px;
  text-decoration: none;
  cursor: pointer;
  &:after {
    display: block;
    content: '';
    width: 100%;
    border-bottom: solid 3px #8d363f;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover {
    &:after {
      transform: scaleX(1);
    }
  }
  ${space}
`
type ModifiedImgProps = GatsbyImageFluidProps &
  SpaceProps<AppTheme> &
  LayoutProps<AppTheme> &
  PositionProps<AppTheme> &
  BorderProps<AppTheme>

const Logo: React.FC<Omit<ModifiedImgProps, 'propTypes' | 'ref'>> = styled(Img)`
  z-index: 800;
  position: absolute;

  ${border}
  ${space};
  ${position}
  ${layout}
`

const ModifiedButton = styled.button`
  color: #8d363f;
  font-weight: bold;
  background: #ffffff;
  text-transform: uppercase;

  padding: 10px 20px;
  border: 4px solid #8d363f;
  /* display: inline-block; */
  transition: all 0.4s ease 0s;
  margin-left: 2rem;
  border-radius: 15px;
  :hover {
    color: white !important;
    background: #8d363f;
    /* border-color: black !important; */
    transition: all 0.4s ease 0s;
  }
`

const query = graphql`
  query {
    waves: file(relativePath: { eq: "waves.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoheader: file(relativePath: { eq: "logoheader.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = () => {
  const { toggleMenu, toggleEnglish, toggleItalian } = useDataContext()
  const { logoheader } = useStaticQuery(query)
  const { screenSize } = useScreenSize()
  const [showFullNavbar, setShowFullNavbar] = useState(false)
  const isMobile = screenSize === 'small' || screenSize === 'medium'
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleIsOpen = () => setIsOpen(current => !current)

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    )
  }
  const HeaderToWork = () => {
    const scrollTop = window.pageYOffset
    const windowHeight = window.innerHeight
    const docHeight = getDocHeight()
    const totalDocScrollLength = docHeight - windowHeight
    const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100)
    scrollPosition < 20 ? setShowFullNavbar(false) : setShowFullNavbar(true)
  }
  useEventListener('scroll', HeaderToWork)
  const { language } = useDataContext()

  return (
    <>
      <StyledHeader fullNavbar={showFullNavbar} isMobile={isMobile}>
        <HeaderItemsContainer isMobile={isMobile} fullNavbar={showFullNavbar}>
          {/* <Title color="negative">I CALANCHI</Title> */}
          <LogoTop />
        </HeaderItemsContainer>
        {isMobile ? (
          <HeaderItemsContainer isMobile={isMobile} fullNavbar={showFullNavbar}>
            <Burger
              isOpen={isOpen}
              onClick={toggleIsOpen}
              isMobile={isMobile}
              fullNavbar={showFullNavbar}
            />
          </HeaderItemsContainer>
        ) : (
          <>
            <HeaderItemsContainer
              isMobile={isMobile}
              fullNavbar={showFullNavbar}
            >
              <Link isMobile={isMobile} href="#agriturismo">
                {language === 'ITA' ? 'AGRITURISMO' : 'FARMHOUSE'}
              </Link>
              <Link isMobile={isMobile} href="#agricoltura" mx={2}>
                {language === 'ITA' ? 'AGRICOLTURA' : 'AGRICULTURE'}
              </Link>
              <Link isMobile={isMobile} href="#cucina">
                {language === 'ITA' ? 'LA CUCINA' : 'THE KITCHEN'}
              </Link>
              <Link isMobile={isMobile} href="#camere" mx={2}>
                {language === 'ITA' ? 'LE CAMERE' : 'ROOMS'}
              </Link>
              <Link isMobile={isMobile} href="#dove-siamo">
                {language === 'ITA' ? 'DOVE SIAMO' : 'FIND US'}
              </Link>
            </HeaderItemsContainer>
            <HeaderItemsContainer>
              <Link onClick={() => toggleItalian()} isMobile={isMobile} mx={2}>
                IT
              </Link>
              <Link onClick={() => toggleEnglish()} isMobile={isMobile}>
                EN
              </Link>
            </HeaderItemsContainer>
            <HeaderItemsContainer
              isMobile={isMobile}
              fullNavbar={showFullNavbar}
            >
              <Paragraph fontWeight="700" color="negative">
                <FontAwesomeIcon color="negative" icon={faPhone} />
                +39 347 494 4789
              </Paragraph>
              {showFullNavbar && (
                <ModifiedButton onClick={() => toggleMenu()}>
                  MENU
                </ModifiedButton>
              )}
            </HeaderItemsContainer>
          </>
        )}
      </StyledHeader>
      {isMobile && (
        <SideNav isOpen={isOpen}>
          <Link
            isMobile={isMobile}
            onClick={() => toggleIsOpen()}
            href="#agriturismo"
          >
            {language === 'ITA' ? 'AGRITURISMO' : 'FARMHOUSE'}
          </Link>
          <Link
            isMobile={isMobile}
            onClick={() => toggleIsOpen()}
            href="#agricoltura"
          >
            {language === 'ITA' ? 'AGRICOLTURA' : 'AGRICULTURE'}
          </Link>
          <Link
            isMobile={isMobile}
            onClick={() => toggleIsOpen()}
            href="#cucina"
          >
            {language === 'ITA' ? 'LA CUCINA' : 'THE KITCHEN'}
          </Link>
          <Link
            isMobile={isMobile}
            onClick={() => toggleIsOpen()}
            href="#camere"
          >
            {language === 'ITA' ? 'LE CAMERE' : 'ROOMS'}
          </Link>
          <Link
            isMobile={isMobile}
            onClick={() => toggleIsOpen()}
            href="#dove-siamo"
          >
            {language === 'ITA' ? 'DOVE SIAMO' : 'FIND US'}
          </Link>
          <ContentWrapper>
            <Link
              isMobile={isMobile}
              onClick={() => {
                toggleIsOpen()
                toggleMenu()
              }}
            >
              MENU
            </Link>
          </ContentWrapper>
          <ContentWrapper>
            <Link
              isMobile={isMobile}
              onClick={() => {
                toggleIsOpen()
                toggleItalian()
              }}
              marginRight="0.5rem"
            >
              IT
            </Link>
            |
            <Link
              isMobile={isMobile}
              onClick={() => {
                toggleIsOpen()
                toggleEnglish()
              }}
              marginLeft="0.5rem"
            >
              EN
            </Link>
          </ContentWrapper>
        </SideNav>
      )}
    </>
  )
}
export default Header
