import React, { HTMLProps } from 'react'
import { AppTheme, getColor } from 'src/styles/theme'
import styled from 'styled-components'
import {
  TypographyProps,
  typography,
  layout,
  LayoutProps,
  SpaceProps,
  space,
  color,
  ColorProps,
  FlexboxProps,
  flexbox
} from 'styled-system'

type PProps = HTMLProps<HTMLParagraphElement> &
  TypographyProps<AppTheme> &
  LayoutProps<AppTheme> &
  SpaceProps<AppTheme> &
  FlexboxProps<AppTheme> &
  ColorProps

const Paragraph: React.FC<PProps> = styled.p`
  text-align: center;
  /* border-left: 2px solid red; */
  /* font-family: 'normalFont'; */
  color: ${getColor('neutralDark')};
  ${typography};
  ${space}
  ${layout}
  ${color}
  ${flexbox};
`

export default Paragraph
