import * as React from 'react'
import { useDataContext } from 'src/contexts/dataContext'
import styled from 'styled-components'

const StyledLayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface LayoutMainProps {
  className?: string
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => {
  return <StyledLayoutMain>{children}</StyledLayoutMain>
}

export default LayoutMain
