import React, { ButtonHTMLAttributes } from 'react'
import { getColor } from 'src/styles/theme'

import styled from 'styled-components'

interface RectProps {
  isFullNavbar?: boolean
  isMobile?: boolean
  isOpen?: boolean
  down?: boolean
}
const BurgerButton = styled.button<RectProps>`
  background: inherit;
  border: none;

  padding: 0;
  z-index: 101;

  &:focus {
    outline: none;
  }
`

const MiddleRect = styled.rect<RectProps>`
  transition: 0.4s;
  opacity: ${props => (props.isOpen ? '0' : '1')};
  fill: ${props =>
    props.isFullNavbar || props.isMobile
      ? getColor('negative')
      : getColor('primary')};
`
const TopRect = styled.rect<RectProps>`
  transition: 0.4s;
  transform: ${props =>
    props.isOpen ? 'rotate(-45deg) translate(-48px,50px)' : '0'};
  fill: ${props =>
    props.isFullNavbar || props.isMobile
      ? getColor('negative')
      : getColor('primary')};
`
const BottomRect = styled.rect<RectProps>`
  transition: 0.4s;
  transform: ${props =>
    props.isOpen ? 'rotate(45deg) translate(10px,-70px)' : '0'};
  fill: ${props =>
    props.isFullNavbar || props.isMobile
      ? getColor('negative')
      : getColor('primary')};
`

interface BurgerProps {
  fullNavbar: boolean
  isMobile?: boolean
  isOpen: boolean
}

const Burger = ({
  fullNavbar,
  isMobile,
  isOpen,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & BurgerProps) => {
  return (
    <BurgerButton isOpen={isOpen} isFullNavbar={fullNavbar} {...props}>
      <svg viewBox="0 0 100 80" width="40" height="40">
        <TopRect
          isOpen={isOpen}
          isMobile={isMobile}
          isFullNavbar={fullNavbar}
          width="100"
          height="20"
          rx="8"
        />
        <MiddleRect
          isOpen={isOpen}
          isMobile={isMobile}
          isFullNavbar={fullNavbar}
          y="30"
          width="100"
          height="20"
          rx="8"
        />
        <BottomRect
          isOpen={isOpen}
          isMobile={isMobile}
          isFullNavbar={fullNavbar}
          y="60"
          width="100"
          height="20"
          rx="8"
        />
      </svg>
    </BurgerButton>
  )
}

export { Burger }
