import { AppTheme } from 'src/styles/theme'

import { HTMLProps } from 'react'
import styled from 'styled-components'
import {
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  flexbox,
  color,
  layout,
  space,
  PositionProps,
  position,
  BorderProps,
  border
} from 'styled-system'

type DivProps = HTMLProps<HTMLDivElement> &
  FlexboxProps<AppTheme> &
  SpaceProps<AppTheme> &
  LayoutProps<AppTheme> &
  PositionProps<AppTheme> &
  BorderProps<AppTheme> &
  ColorProps

const ContentWrapper: React.FC<DivProps> = styled.div`
  height: auto;
  display: flex;

  ${flexbox};
  ${border};
  ${position};
  ${color};
  ${layout};
  ${space};
`

ContentWrapper.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center'
}

export { ContentWrapper }
