import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  padding-bottom: 3rem;
  width: 35rem;
`
const Svg3 = styled.svg`
  width: 8rem;
`
const Svg2 = styled.svg`
  width: 13rem;
`
const Path = styled.path`
  fill-rule: evenodd;
  fill: #843533;
`
const Polygon = styled.polygon`
  fill: none;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
`
const Polyline = styled.polyline`
  fill: none;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
`

const Path2 = styled.path`
  fill: #832d2f;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
  fill-rule: evenodd;
`
const Path3 = styled.path`
  fill: none;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
`
export const Logo = () => (
  <Svg viewBox="0 0 427.09 219.62">
    <Path
      d="M22.15,207.43,17,195.58l-5.24,11.85Zm.62,1.6H11.1l-2,4.62a7.21,7.21,0,0,0-.76,2.56A1.48,1.48,0,0,0,9,217.38a6,6,0,0,0,2.84.66v.8H2.3V218a5.24,5.24,0,0,0,2.45-.84A13.18,13.18,0,0,0,7.25,213l10.61-24.12h.77l10.5,24.38a11,11,0,0,0,2.3,3.82,4.77,4.77,0,0,0,2.88,1v.8H22.41v-.8a4.44,4.44,0,0,0,2.44-.59,1.47,1.47,0,0,0,.63-1.21,9,9,0,0,0-.89-3Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M73.71,188.86l.77,9.25h-.77a12.61,12.61,0,0,0-3-5.21,9.67,9.67,0,0,0-6.91-2.53A9.92,9.92,0,0,0,55,194.8a15.72,15.72,0,0,0-2.51,8.91,17.45,17.45,0,0,0,1.67,7.65,11.45,11.45,0,0,0,4.37,5.07A10.76,10.76,0,0,0,64.06,218a13,13,0,0,0,3.23-.41,13.66,13.66,0,0,0,3-1.21V208a7.63,7.63,0,0,0-.34-2.89,2.35,2.35,0,0,0-1.07-1,6.26,6.26,0,0,0-2.54-.35v-.82H78v.82h-.56a2.51,2.51,0,0,0-2.38,1.12,7.56,7.56,0,0,0-.44,3.16v9a21.45,21.45,0,0,1-10.58,2.59q-8.73,0-13.27-5.45a14.3,14.3,0,0,1-3.4-9.42,15.52,15.52,0,0,1,1.91-7.42,16.1,16.1,0,0,1,6.22-6.48,15.6,15.6,0,0,1,7.83-1.88,15.86,15.86,0,0,1,3,.26,26.54,26.54,0,0,1,3.81,1.15,6.08,6.08,0,0,0,1.67.45.9.9,0,0,0,.72-.38,2.73,2.73,0,0,0,.36-1.48Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M98.89,203.77h1.25q4.31,0,6.5-1.82a5.76,5.76,0,0,0,2.19-4.62,6,6,0,0,0-1.77-4.47,6.43,6.43,0,0,0-4.68-1.72,20.52,20.52,0,0,0-3.49.41v12.22Zm21.41,15.07h-8l-10.2-13.7H98.89v8.51c0,1.85.21,3,.63,3.44a3.23,3.23,0,0,0,2.53.95h1.18v.8H90.32V218h1.13a3.08,3.08,0,0,0,2.73-1.21,6.79,6.79,0,0,0,.47-3.18V194.72c0-1.84-.21-3-.62-3.44a3.33,3.33,0,0,0-2.58-.95H90.32v-.8h11a26.69,26.69,0,0,1,7.07.68,8.21,8.21,0,0,1,3.87,2.51,6.4,6.4,0,0,1,1.59,4.36,6.78,6.78,0,0,1-1.81,4.69,10.31,10.31,0,0,1-5.61,2.8l6.22,8.41a15.27,15.27,0,0,0,3.67,3.85,9.41,9.41,0,0,0,4,1.21Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M144.87,218v.8H132V218H133a3.26,3.26,0,0,0,2.69-1.08c.36-.46.53-1.57.53-3.33V194.72a9.7,9.7,0,0,0-.28-2.94,2,2,0,0,0-.91-.93,4.31,4.31,0,0,0-2.05-.52h-1.07v-.8h12.92v.8h-1.09a3.23,3.23,0,0,0-2.69,1.06c-.37.46-.55,1.57-.55,3.33v18.93a9.7,9.7,0,0,0,.28,2.94,2,2,0,0,0,.94.93,4.11,4.11,0,0,0,2,.52Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M183.24,189.53l.33,6.87h-.86a8.32,8.32,0,0,0-.67-2.59,4.41,4.41,0,0,0-1.83-1.85,6.6,6.6,0,0,0-3-.59H173v22.39c0,1.8.2,2.92.6,3.37a3.37,3.37,0,0,0,2.6.91h1v.8H164.44V218h1.07a3.13,3.13,0,0,0,2.71-1.12,6.57,6.57,0,0,0,.48-3.16V191.37h-3.62a10.45,10.45,0,0,0-3,.3,4.11,4.11,0,0,0-2,1.58,6.3,6.3,0,0,0-1,3.15h-.85l.36-6.87Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M217.35,190.33v-.8H228v.8h-1.14a3.08,3.08,0,0,0-2.73,1.45,6.48,6.48,0,0,0-.47,3.11v11.89a20.32,20.32,0,0,1-.9,6.84,8.67,8.67,0,0,1-3.53,4.17,12.87,12.87,0,0,1-7.17,1.74q-4.94,0-7.49-1.66a9.07,9.07,0,0,1-3.62-4.48c-.48-1.28-.72-3.68-.72-7.22V194.72c0-1.8-.25-3-.76-3.55a3.23,3.23,0,0,0-2.48-.84h-1.13v-.8h13v.8h-1.17a3,3,0,0,0-2.66,1.15,6.23,6.23,0,0,0-.56,3.24v12.77a28.37,28.37,0,0,0,.32,3.91,8.29,8.29,0,0,0,1.17,3.44,6.3,6.3,0,0,0,2.43,2,8.63,8.63,0,0,0,3.91.8,11.06,11.06,0,0,0,5.29-1.25,6.85,6.85,0,0,0,3.19-3.21,18.13,18.13,0,0,0,.85-6.62V194.72c0-1.83-.21-3-.62-3.44a3.33,3.33,0,0,0-2.58-.95Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M248.79,203.77H250c2.88,0,5-.61,6.5-1.82a5.73,5.73,0,0,0,2.18-4.59,6,6,0,0,0-1.76-4.47,6.47,6.47,0,0,0-4.68-1.72,20.52,20.52,0,0,0-3.49.41v12.19Zm21.4,15.07h-8.05l-10.2-13.7h-3.15v8.51c0,1.85.2,3,.62,3.44a3.23,3.23,0,0,0,2.53.95h1.18v.8H240.21V218h1.13a3.06,3.06,0,0,0,2.73-1.21,6.53,6.53,0,0,0,.47-3.18V194.72c0-1.84-.21-3-.62-3.44a3.33,3.33,0,0,0-2.58-.95h-1.13v-.8h11a26.76,26.76,0,0,1,7.08.68,8.18,8.18,0,0,1,3.86,2.51,6.4,6.4,0,0,1,1.59,4.36,6.78,6.78,0,0,1-1.81,4.69,10.31,10.31,0,0,1-5.61,2.8l6.22,8.41a15.27,15.27,0,0,0,3.67,3.85,9.41,9.41,0,0,0,4,1.21Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M294.76,218v.8H281.85V218h1.07a3.27,3.27,0,0,0,2.71-1.06c.35-.46.53-1.57.53-3.33V194.72a9.4,9.4,0,0,0-.29-2.94,2,2,0,0,0-.91-.93,4.23,4.23,0,0,0-2-.52h-1.07v-.8H294.8v.8h-1.09a3.23,3.23,0,0,0-2.69,1.06c-.37.46-.56,1.57-.56,3.33v18.93a9.12,9.12,0,0,0,.3,2.94,1.91,1.91,0,0,0,.93.93,4.11,4.11,0,0,0,2,.52Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M327.71,188.86V199h-.83a12.29,12.29,0,0,0-1.43-4.65,7.32,7.32,0,0,0-2.94-2.74,8.33,8.33,0,0,0-4-1,5.47,5.47,0,0,0-3.8,1.39,4.12,4.12,0,0,0-1.51,3.12,3.63,3.63,0,0,0,.95,2.45q1.38,1.62,6.56,4.32a47.55,47.55,0,0,1,5.77,3.38,8.24,8.24,0,0,1,2.37,2.78,7.11,7.11,0,0,1,.84,3.35A7.52,7.52,0,0,1,327,217.1a9.77,9.77,0,0,1-6.83,2.41,14.6,14.6,0,0,1-2.47-.19,28.13,28.13,0,0,1-2.86-.79,12.35,12.35,0,0,0-2.74-.68,1.2,1.2,0,0,0-.88.32,2.42,2.42,0,0,0-.47,1.34H310v-10h.83a13.88,13.88,0,0,0,1.55,4.72,7.36,7.36,0,0,0,3,2.61,9.5,9.5,0,0,0,4.41,1,6.41,6.41,0,0,0,4.39-1.42,4.3,4.3,0,0,0,1-5.56,6.65,6.65,0,0,0-1.9-2.05q-.87-.64-4.74-2.76a45.41,45.41,0,0,1-5.5-3.36,9.1,9.1,0,0,1-2.47-2.77,6.65,6.65,0,0,1-.85-3.32,7.11,7.11,0,0,1,2.49-5.44,9,9,0,0,1,6.33-2.28,12.82,12.82,0,0,1,5.1,1.15,5.39,5.39,0,0,0,1.75.54,1.35,1.35,0,0,0,.94-.34,2.5,2.5,0,0,0,.59-1.35Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M361.71,218.84l-11.64-24.66v19.58c0,1.8.19,2.93.59,3.37a3.36,3.36,0,0,0,2.61.91h1.06v.8H343.79V218h1.07a3.13,3.13,0,0,0,2.71-1.12,6.58,6.58,0,0,0,.49-3.16V194.61a6.42,6.42,0,0,0-.45-2.81,2.36,2.36,0,0,0-1.14-1,6.29,6.29,0,0,0-2.68-.42v-.8h8.54l10.93,22.94L374,189.58h8.53v.8h-1a3.17,3.17,0,0,0-2.74,1.13,6.53,6.53,0,0,0-.48,3.15v19.1c0,1.8.2,2.93.62,3.37a3.32,3.32,0,0,0,2.6.91h1v.8H369.71V218h1.07a3.08,3.08,0,0,0,2.71-1.12,6.33,6.33,0,0,0,.49-3.16V194.18l-11.63,24.66Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M410.71,190.44a8,8,0,0,0-6.49,2.92q-3,3.63-3,10.63,0,7.19,3.16,11a7.86,7.86,0,0,0,6.33,3,8.86,8.86,0,0,0,7-3.23q2.78-3.21,2.77-10.15,0-7.53-3-11.22a8.3,8.3,0,0,0-6.76-2.94Zm.42-1.58a13.86,13.86,0,0,1,10.17,4.34A14.72,14.72,0,0,1,425.6,204a15.22,15.22,0,0,1-4.33,11.09,14.77,14.77,0,0,1-20.9.16l-.05,0a15.2,15.2,0,0,1-4.24-11.13,14.85,14.85,0,0,1,14.47-15.22Z"
      transform="translate(0 0.03)"
    />
    <Path2 d="M3,174.16Z" transform="translate(0 0.03)" />
    <Path
      d="M16.76,149l.3,4.24a8.61,8.61,0,0,0,2.13,5A6,6,0,0,0,23.63,160c1.19,0,1.79.43,1.79,1.29s-.79,1.25-2.37,1.25a26.08,26.08,0,0,1-2.93-.22,60.09,60.09,0,0,0-7.7-.62,28.61,28.61,0,0,0-5.77.44,13.7,13.7,0,0,1-2.63.4,1,1,0,0,1-1-.91L3,161.25l1.06-1a6.58,6.58,0,0,0,3.49-2.69,11.92,11.92,0,0,0,.86-5.28V114.75c0-2.6-.38-4.45-1.16-5.55A6.38,6.38,0,0,0,2.92,107a8,8,0,0,1-2.39-.67A1.17,1.17,0,0,1,0,105.2c0-.81.29-1.2.88-1.17a15.22,15.22,0,0,1,2,.35,20.49,20.49,0,0,0,4.42.33c1.36,0,3-.09,5.07-.28l2.67-.24c2.51-.22,4.43-.32,5.77-.32.81,0,1.21.34,1.21,1a1.93,1.93,0,0,1-.19,1,1.47,1.47,0,0,1-.91.39c-1.75.34-2.88.9-3.38,1.69s-.75,2.41-.75,4.84Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M71.71,103.09a20.88,20.88,0,0,1,11.91,3.39L86,108.1a2.34,2.34,0,0,0,1.31.51,1.18,1.18,0,0,0,1.1-.68l.77-1.32a.7.7,0,0,1,.66-.34c.75,0,1.13.92,1.13,2.76V116l.3,3.22.32,1.91a16.26,16.26,0,0,1,.22,2.51c0,1.07-.34,1.61-1,1.61-.39,0-.64-.34-.77-1a19.94,19.94,0,0,0-2-4.93,33,33,0,0,0-3.3-5.27q-6.36-8-14.15-8-6.91,0-11.23,6.59t-4.31,17.11q0,13.72,7.05,21.92,5.31,6.09,11.56,6.1a17.52,17.52,0,0,0,7.82-2.06A18.38,18.38,0,0,0,88,150.58l2.38-3a1.54,1.54,0,0,1,1-.72c.47,0,.69.28.69.93q0,1.76-2.88,5.56-7.56,9.94-18.66,9.94a21.69,21.69,0,0,1-17.44-8q-6.7-8-6.71-20.77a38.46,38.46,0,0,1,2.53-13.71A30.25,30.25,0,0,1,55.61,110,22.11,22.11,0,0,1,71.71,103.09Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M121.28,135.45l-4.77-13q-1.71-4.67-2.43-4.66c-.3,0-.83,1-1.61,3l-.69,1.78L107.51,134l-.39,1.06a2.81,2.81,0,0,0-.26,1.1c0,1,.87,1.44,2.63,1.44h10.77c.93,0,1.39-.29,1.39-.88a2.3,2.3,0,0,0-.18-.73l-.19-.46Zm-1.36-26.55,16.61,44.34a13.7,13.7,0,0,0,2.92,5,8.42,8.42,0,0,0,4.35,2.2c.44.09.66.49.66,1.21s-.28,1.16-.84,1.13a13.27,13.27,0,0,1-1.4-.26,40.6,40.6,0,0,0-9.07-.72,36.17,36.17,0,0,0-8.56.67,3.13,3.13,0,0,1-1,.18c-.68,0-1-.4-1-1.21,0-.41.26-.71.8-.91a6,6,0,0,0,2.91-2.13,5.32,5.32,0,0,0,1.15-3.22q0-2.38-3.17-10.45c-.66-1.64-1.38-2.58-2.17-2.82s-3.59-.36-8.4-.36h-5l-3.28.67c-.9.49-1.92,2.35-3.06,5.6l-1.13,3.26a8.1,8.1,0,0,0-.51,2.84q0,5.34,6.43,6.18c.93.15,1.37.56,1.35,1.26s-.49,1.12-1.32,1.12a9.68,9.68,0,0,1-1.86-.25,19.59,19.59,0,0,0-4.32-.55c-1.15,0-3,.14-5.56.42-1.91.22-3.09.34-3.55.34a.91.91,0,0,1-1-.81.61.61,0,0,1,0-.19,1.4,1.4,0,0,1,1-1.3,6.08,6.08,0,0,0,3.15-2.65,57.1,57.1,0,0,0,3.52-8.11l10-26.71q1.2-3.18,1.68-4.37l3.39-8.1,1.76-4v.13c.78-2.16,1.3-3.23,1.57-3.23s1.06,1.19,2,3.56Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M160.41,149q0,7.92,1.64,8.9a7.64,7.64,0,0,0,3.57.55q12.38,0,17.54-8.46c1.07-1.72,1.73-2.58,2-2.58.45,0,.67.58.67,1.75a16.19,16.19,0,0,1-3,9.63,8.45,8.45,0,0,1-7.27,3.74c-.64,0-1.79-.07-3.47-.21l-4.38-.32-4-.21c-1.34-.09-2.86-.13-4.57-.13a68.13,68.13,0,0,0-9.13.65,19.07,19.07,0,0,1-2,.2c-.71,0-1.06-.38-1.06-1.13,0-.52.32-.88,1-1.08a5,5,0,0,0,3.23-2.94,20.33,20.33,0,0,0,.9-7.14V114.74q0-6.76-4.86-7.66l-2.19-.43c-.92-.17-1.39-.6-1.39-1.29,0-.86.37-1.3,1.1-1.3a7.49,7.49,0,0,1,1.06.13,14.24,14.24,0,0,1,1.43.17l1.57.13c1.1.14,2.22.22,3.33.25a47.75,47.75,0,0,0,5.6-.51,51.3,51.3,0,0,1,5.81-.33c1.47,0,2.2.4,2.2,1.21s-.52,1.06-1.57,1.12q-3.69.3-3.69,5.89Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M214.29,135.45l-4.77-13c-1.14-3.11-2-4.66-2.44-4.66s-.83,1-1.6,3l-.7,1.78L200.52,134l-.4,1.06a3,3,0,0,0-.26,1.1c0,1,.88,1.44,2.63,1.44h10.78c.92,0,1.39-.29,1.39-.88a2.62,2.62,0,0,0-.19-.73l-.18-.46Zm-1.37-26.55,16.62,44.34a13.7,13.7,0,0,0,2.92,5,8.38,8.38,0,0,0,4.35,2.2c.44.09.65.49.65,1.21s-.28,1.16-.84,1.13a13.08,13.08,0,0,1-1.39-.26,40.6,40.6,0,0,0-9.07-.72,36.17,36.17,0,0,0-8.56.67,3.13,3.13,0,0,1-.95.18c-.68,0-1-.4-1-1.21,0-.41.27-.71.81-.91a6,6,0,0,0,2.9-2.13,5.23,5.23,0,0,0,1.15-3.22q0-2.38-3.17-10.45-1-2.46-2.16-2.82c-.8-.24-3.59-.36-8.4-.36h-5l-3.28.67c-.9.49-1.92,2.35-3.06,5.6l-1.13,3.26a8.1,8.1,0,0,0-.51,2.84q0,5.34,6.43,6.18c.92.15,1.37.56,1.35,1.26s-.49,1.12-1.32,1.12a9.68,9.68,0,0,1-1.86-.25,19.66,19.66,0,0,0-4.32-.55c-1.15,0-3,.14-5.57.42-1.9.22-3.08.34-3.55.34a.91.91,0,0,1-1-.81.61.61,0,0,1,0-.19,1.4,1.4,0,0,1,1-1.3,6.1,6.1,0,0,0,3.16-2.65,57.1,57.1,0,0,0,3.52-8.11l10-26.71q1.22-3.18,1.68-4.37l3.4-8.1,1.75-4v.13c.78-2.16,1.31-3.23,1.57-3.23s1.06,1.19,2,3.56Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M257.71,113.79,277,139l1.24,1.62c1.65,2.14,2.75,3.22,3.32,3.22s.78-.86.83-2.59q.33-9.36.33-17.85,0-10-1.73-13.49c-1.16-2.35-3.43-3.66-6.81-4-.81,0-1.21-.39-1.21-1a1,1,0,0,1,1.06-1.13,31.73,31.73,0,0,1,4.24.43,25.55,25.55,0,0,0,3.15.25,63.2,63.2,0,0,0,8-.8,9.47,9.47,0,0,1,1.06-.09c.78,0,1.17.4,1.17,1.21a1.23,1.23,0,0,1-1.17,1.29h0c-3.14.69-4.71,3.92-4.71,9.72V118l-.52,26.52-.15,3.65v7.93l.15,5.3c0,.8-.24,1.18-.74,1.15a2.07,2.07,0,0,1-1.35-.93L250,118.65c-.59-.74-1-1.1-1.21-1.1-.58,0-.87.63-.87,1.9v17.69q0,13.2,1.13,17.65,1.22,4.76,7.13,5.38c.72.09,1.09.45,1.09,1.09,0,.86-.38,1.3-1.13,1.3a8.6,8.6,0,0,1-1.43-.18,36.22,36.22,0,0,0-6.81-.59,31.71,31.71,0,0,0-6.59.61,11.54,11.54,0,0,1-1.86.24.89.89,0,0,1-1-1c0-.65.35-1,1.06-1.21a6.39,6.39,0,0,0,3.89-3.67,20.39,20.39,0,0,0,1.23-8l.25-10.08V120.25q0-13.05-8.29-13.72c-.75-.06-1.13-.42-1.13-1.08a1.08,1.08,0,0,1,.94-1.22,1,1,0,0,1,.3,0l2.53.13h1.54a54.32,54.32,0,0,0,6.11-.38,19,19,0,0,1,2-.13,2.61,2.61,0,0,1,1.78.57,28.4,28.4,0,0,1,2.77,3.5l2.52,3.44C257,112.69,257.55,113.51,257.71,113.79Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M320.46,103.09a21,21,0,0,1,11.91,3.39l2.41,1.62a2.37,2.37,0,0,0,1.32.51,1.18,1.18,0,0,0,1.09-.68l.77-1.32a.7.7,0,0,1,.66-.34c.75,0,1.13.92,1.13,2.76V116l.3,3.22.32,1.91a16.26,16.26,0,0,1,.22,2.51c0,1.07-.34,1.61-1,1.61-.39,0-.64-.34-.77-1a19.94,19.94,0,0,0-2-4.93,32.32,32.32,0,0,0-3.3-5.27q-6.37-8-14.15-8-6.92,0-11.23,6.59t-4.33,17.16q0,13.73,7.06,21.92,5.29,6.09,11.55,6.1a17.52,17.52,0,0,0,7.82-2.06,18.3,18.3,0,0,0,6.51-5.09l2.38-3a1.54,1.54,0,0,1,1-.72c.46,0,.7.28.7.93,0,1.17-1,3-2.89,5.56q-7.56,9.94-18.66,9.94a21.69,21.69,0,0,1-17.44-8q-6.7-8-6.71-20.77a38.68,38.68,0,0,1,2.53-13.71,30.13,30.13,0,0,1,6.7-10.9A22.11,22.11,0,0,1,320.46,103.09Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M380.83,133.28H363.52c-1.73,0-2.8.26-3.2.78s-.6,1.9-.6,4.14v10.55c0,4,.55,6.91,1.68,8.67s3,2.65,5.51,2.65c.83,0,1.24.37,1.24,1.13s-.63,1.34-1.9,1.34a25,25,0,0,1-2.59-.26,58.28,58.28,0,0,0-7.92-.6,52.34,52.34,0,0,0-6,.47c-1.49.2-2.42.3-2.81.3-.78,0-1.17-.48-1.17-1.43a.94.94,0,0,1,.88-1h0a4.7,4.7,0,0,0,3.56-2.64,15.22,15.22,0,0,0,1.08-6.57V113.1a6.3,6.3,0,0,0-1.45-4.56c-1-1-2.74-1.76-5.35-2.35a1.2,1.2,0,0,1-1-1.26c0-.83.39-1.25,1.17-1.25a17.85,17.85,0,0,1,2.41.31,38.44,38.44,0,0,0,6.94.54q1.35,0,5.77-.55a38.24,38.24,0,0,1,4-.3q1.32,0,1.32,1.17a1.14,1.14,0,0,1-1.06,1.17c-2.88.56-4.31,2.67-4.31,6.31v10.9c0,2.88.17,4.56.53,5.06s1.53.74,3.52.74h17.06c1.48,0,2.39-.35,2.72-1s.49-2.61.49-5.75v-9.13c0-3.62-1.72-5.81-5.15-6.54l-1.68-.38a1.17,1.17,0,0,1-1-1.26c0-.83.39-1.25,1.17-1.25a17.85,17.85,0,0,1,2.41.31,39.1,39.1,0,0,0,7,.54q1.38,0,5.77-.55a38.24,38.24,0,0,1,4-.3c.87,0,1.31.39,1.31,1.17a1.14,1.14,0,0,1-1.06,1.17c-2.87.56-4.31,2.67-4.31,6.31v36.42q0,6,1.66,8.67a5.88,5.88,0,0,0,5.43,2.65c.9,0,1.35.37,1.35,1.13s-.64,1.34-1.9,1.34a24.39,24.39,0,0,1-2.56-.26,58.28,58.28,0,0,0-7.92-.6,51.35,51.35,0,0,0-6.07.47c-1.48.2-2.42.3-2.81.3-.78,0-1.17-.48-1.17-1.43a1,1,0,0,1,.88-1h0a4.73,4.73,0,0,0,3.56-2.63,15.54,15.54,0,0,0,1.07-6.57V139.24c0-2.77-.16-4.44-.48-5S382.34,133.28,380.83,133.28Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M418.44,149l.29,4.24a8.53,8.53,0,0,0,2.14,5,5.88,5.88,0,0,0,4.43,1.74c1.2,0,1.79.43,1.79,1.29s-.79,1.25-2.37,1.25a25.9,25.9,0,0,1-2.92-.22,60.25,60.25,0,0,0-7.71-.62,29.35,29.35,0,0,0-5.77.44,13.7,13.7,0,0,1-2.63.4,1,1,0,0,1-1-.91l-.07-.34,1.05-1a6.58,6.58,0,0,0,3.49-2.69,11.92,11.92,0,0,0,.86-5.28V114.75q0-3.9-1.17-5.55a6.35,6.35,0,0,0-4.27-2.21,8,8,0,0,1-2.4-.67,1.17,1.17,0,0,1-.53-1.09c0-.81.29-1.2.88-1.17a15.22,15.22,0,0,1,2,.35,20.49,20.49,0,0,0,4.42.33c1.37,0,3-.09,5.08-.28l2.66-.24c2.51-.22,4.44-.32,5.77-.32.81,0,1.21.34,1.21,1a1.92,1.92,0,0,1-.18,1,1.5,1.5,0,0,1-.92.39c-1.75.34-2.88.9-3.38,1.69s-.74,2.41-.74,4.84Z"
      transform="translate(0 0.03)"
    />
    <Path3
      d="M.92,91.27H127.58V76.14s-9.74-21.28-7.69-38.64S131.17.5,131.17.5,144,19,144,40.83s-6.66,33.6-6.66,33.6V89.55h11.79V49.82l24.1-15.68h21l15.89,15.12s3.59,14,20,16.24S284,71.66,292.67,76.7A32,32,0,0,1,305,89.58H425"
      transform="translate(0 0.03)"
    />
    <Polygon points="162.47 66.09 169.63 66.09 169.63 54.33 161.94 54.33 162.47 66.09" />
    <Polyline points="174.25 90.17 174.25 71.13 185.03 71.13 185.03 90.17" />
  </Svg>
)

export const Logo2 = () => (
  <Svg2 viewBox="0 0 427.09 219.62">
    <Path
      d="M22.15,207.43,17,195.58l-5.24,11.85Zm.62,1.6H11.1l-2,4.62a7.21,7.21,0,0,0-.76,2.56A1.48,1.48,0,0,0,9,217.38a6,6,0,0,0,2.84.66v.8H2.3V218a5.24,5.24,0,0,0,2.45-.84A13.18,13.18,0,0,0,7.25,213l10.61-24.12h.77l10.5,24.38a11,11,0,0,0,2.3,3.82,4.77,4.77,0,0,0,2.88,1v.8H22.41v-.8a4.44,4.44,0,0,0,2.44-.59,1.47,1.47,0,0,0,.63-1.21,9,9,0,0,0-.89-3Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M73.71,188.86l.77,9.25h-.77a12.61,12.61,0,0,0-3-5.21,9.67,9.67,0,0,0-6.91-2.53A9.92,9.92,0,0,0,55,194.8a15.72,15.72,0,0,0-2.51,8.91,17.45,17.45,0,0,0,1.67,7.65,11.45,11.45,0,0,0,4.37,5.07A10.76,10.76,0,0,0,64.06,218a13,13,0,0,0,3.23-.41,13.66,13.66,0,0,0,3-1.21V208a7.63,7.63,0,0,0-.34-2.89,2.35,2.35,0,0,0-1.07-1,6.26,6.26,0,0,0-2.54-.35v-.82H78v.82h-.56a2.51,2.51,0,0,0-2.38,1.12,7.56,7.56,0,0,0-.44,3.16v9a21.45,21.45,0,0,1-10.58,2.59q-8.73,0-13.27-5.45a14.3,14.3,0,0,1-3.4-9.42,15.52,15.52,0,0,1,1.91-7.42,16.1,16.1,0,0,1,6.22-6.48,15.6,15.6,0,0,1,7.83-1.88,15.86,15.86,0,0,1,3,.26,26.54,26.54,0,0,1,3.81,1.15,6.08,6.08,0,0,0,1.67.45.9.9,0,0,0,.72-.38,2.73,2.73,0,0,0,.36-1.48Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M98.89,203.77h1.25q4.31,0,6.5-1.82a5.76,5.76,0,0,0,2.19-4.62,6,6,0,0,0-1.77-4.47,6.43,6.43,0,0,0-4.68-1.72,20.52,20.52,0,0,0-3.49.41v12.22Zm21.41,15.07h-8l-10.2-13.7H98.89v8.51c0,1.85.21,3,.63,3.44a3.23,3.23,0,0,0,2.53.95h1.18v.8H90.32V218h1.13a3.08,3.08,0,0,0,2.73-1.21,6.79,6.79,0,0,0,.47-3.18V194.72c0-1.84-.21-3-.62-3.44a3.33,3.33,0,0,0-2.58-.95H90.32v-.8h11a26.69,26.69,0,0,1,7.07.68,8.21,8.21,0,0,1,3.87,2.51,6.4,6.4,0,0,1,1.59,4.36,6.78,6.78,0,0,1-1.81,4.69,10.31,10.31,0,0,1-5.61,2.8l6.22,8.41a15.27,15.27,0,0,0,3.67,3.85,9.41,9.41,0,0,0,4,1.21Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M144.87,218v.8H132V218H133a3.26,3.26,0,0,0,2.69-1.08c.36-.46.53-1.57.53-3.33V194.72a9.7,9.7,0,0,0-.28-2.94,2,2,0,0,0-.91-.93,4.31,4.31,0,0,0-2.05-.52h-1.07v-.8h12.92v.8h-1.09a3.23,3.23,0,0,0-2.69,1.06c-.37.46-.55,1.57-.55,3.33v18.93a9.7,9.7,0,0,0,.28,2.94,2,2,0,0,0,.94.93,4.11,4.11,0,0,0,2,.52Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M183.24,189.53l.33,6.87h-.86a8.32,8.32,0,0,0-.67-2.59,4.41,4.41,0,0,0-1.83-1.85,6.6,6.6,0,0,0-3-.59H173v22.39c0,1.8.2,2.92.6,3.37a3.37,3.37,0,0,0,2.6.91h1v.8H164.44V218h1.07a3.13,3.13,0,0,0,2.71-1.12,6.57,6.57,0,0,0,.48-3.16V191.37h-3.62a10.45,10.45,0,0,0-3,.3,4.11,4.11,0,0,0-2,1.58,6.3,6.3,0,0,0-1,3.15h-.85l.36-6.87Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M217.35,190.33v-.8H228v.8h-1.14a3.08,3.08,0,0,0-2.73,1.45,6.48,6.48,0,0,0-.47,3.11v11.89a20.32,20.32,0,0,1-.9,6.84,8.67,8.67,0,0,1-3.53,4.17,12.87,12.87,0,0,1-7.17,1.74q-4.94,0-7.49-1.66a9.07,9.07,0,0,1-3.62-4.48c-.48-1.28-.72-3.68-.72-7.22V194.72c0-1.8-.25-3-.76-3.55a3.23,3.23,0,0,0-2.48-.84h-1.13v-.8h13v.8h-1.17a3,3,0,0,0-2.66,1.15,6.23,6.23,0,0,0-.56,3.24v12.77a28.37,28.37,0,0,0,.32,3.91,8.29,8.29,0,0,0,1.17,3.44,6.3,6.3,0,0,0,2.43,2,8.63,8.63,0,0,0,3.91.8,11.06,11.06,0,0,0,5.29-1.25,6.85,6.85,0,0,0,3.19-3.21,18.13,18.13,0,0,0,.85-6.62V194.72c0-1.83-.21-3-.62-3.44a3.33,3.33,0,0,0-2.58-.95Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M248.79,203.77H250c2.88,0,5-.61,6.5-1.82a5.73,5.73,0,0,0,2.18-4.59,6,6,0,0,0-1.76-4.47,6.47,6.47,0,0,0-4.68-1.72,20.52,20.52,0,0,0-3.49.41v12.19Zm21.4,15.07h-8.05l-10.2-13.7h-3.15v8.51c0,1.85.2,3,.62,3.44a3.23,3.23,0,0,0,2.53.95h1.18v.8H240.21V218h1.13a3.06,3.06,0,0,0,2.73-1.21,6.53,6.53,0,0,0,.47-3.18V194.72c0-1.84-.21-3-.62-3.44a3.33,3.33,0,0,0-2.58-.95h-1.13v-.8h11a26.76,26.76,0,0,1,7.08.68,8.18,8.18,0,0,1,3.86,2.51,6.4,6.4,0,0,1,1.59,4.36,6.78,6.78,0,0,1-1.81,4.69,10.31,10.31,0,0,1-5.61,2.8l6.22,8.41a15.27,15.27,0,0,0,3.67,3.85,9.41,9.41,0,0,0,4,1.21Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M294.76,218v.8H281.85V218h1.07a3.27,3.27,0,0,0,2.71-1.06c.35-.46.53-1.57.53-3.33V194.72a9.4,9.4,0,0,0-.29-2.94,2,2,0,0,0-.91-.93,4.23,4.23,0,0,0-2-.52h-1.07v-.8H294.8v.8h-1.09a3.23,3.23,0,0,0-2.69,1.06c-.37.46-.56,1.57-.56,3.33v18.93a9.12,9.12,0,0,0,.3,2.94,1.91,1.91,0,0,0,.93.93,4.11,4.11,0,0,0,2,.52Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M327.71,188.86V199h-.83a12.29,12.29,0,0,0-1.43-4.65,7.32,7.32,0,0,0-2.94-2.74,8.33,8.33,0,0,0-4-1,5.47,5.47,0,0,0-3.8,1.39,4.12,4.12,0,0,0-1.51,3.12,3.63,3.63,0,0,0,.95,2.45q1.38,1.62,6.56,4.32a47.55,47.55,0,0,1,5.77,3.38,8.24,8.24,0,0,1,2.37,2.78,7.11,7.11,0,0,1,.84,3.35A7.52,7.52,0,0,1,327,217.1a9.77,9.77,0,0,1-6.83,2.41,14.6,14.6,0,0,1-2.47-.19,28.13,28.13,0,0,1-2.86-.79,12.35,12.35,0,0,0-2.74-.68,1.2,1.2,0,0,0-.88.32,2.42,2.42,0,0,0-.47,1.34H310v-10h.83a13.88,13.88,0,0,0,1.55,4.72,7.36,7.36,0,0,0,3,2.61,9.5,9.5,0,0,0,4.41,1,6.41,6.41,0,0,0,4.39-1.42,4.3,4.3,0,0,0,1-5.56,6.65,6.65,0,0,0-1.9-2.05q-.87-.64-4.74-2.76a45.41,45.41,0,0,1-5.5-3.36,9.1,9.1,0,0,1-2.47-2.77,6.65,6.65,0,0,1-.85-3.32,7.11,7.11,0,0,1,2.49-5.44,9,9,0,0,1,6.33-2.28,12.82,12.82,0,0,1,5.1,1.15,5.39,5.39,0,0,0,1.75.54,1.35,1.35,0,0,0,.94-.34,2.5,2.5,0,0,0,.59-1.35Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M361.71,218.84l-11.64-24.66v19.58c0,1.8.19,2.93.59,3.37a3.36,3.36,0,0,0,2.61.91h1.06v.8H343.79V218h1.07a3.13,3.13,0,0,0,2.71-1.12,6.58,6.58,0,0,0,.49-3.16V194.61a6.42,6.42,0,0,0-.45-2.81,2.36,2.36,0,0,0-1.14-1,6.29,6.29,0,0,0-2.68-.42v-.8h8.54l10.93,22.94L374,189.58h8.53v.8h-1a3.17,3.17,0,0,0-2.74,1.13,6.53,6.53,0,0,0-.48,3.15v19.1c0,1.8.2,2.93.62,3.37a3.32,3.32,0,0,0,2.6.91h1v.8H369.71V218h1.07a3.08,3.08,0,0,0,2.71-1.12,6.33,6.33,0,0,0,.49-3.16V194.18l-11.63,24.66Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M410.71,190.44a8,8,0,0,0-6.49,2.92q-3,3.63-3,10.63,0,7.19,3.16,11a7.86,7.86,0,0,0,6.33,3,8.86,8.86,0,0,0,7-3.23q2.78-3.21,2.77-10.15,0-7.53-3-11.22a8.3,8.3,0,0,0-6.76-2.94Zm.42-1.58a13.86,13.86,0,0,1,10.17,4.34A14.72,14.72,0,0,1,425.6,204a15.22,15.22,0,0,1-4.33,11.09,14.77,14.77,0,0,1-20.9.16l-.05,0a15.2,15.2,0,0,1-4.24-11.13,14.85,14.85,0,0,1,14.47-15.22Z"
      transform="translate(0 0.03)"
    />
    <Path2 d="M3,174.16Z" transform="translate(0 0.03)" />
    <Path
      d="M16.76,149l.3,4.24a8.61,8.61,0,0,0,2.13,5A6,6,0,0,0,23.63,160c1.19,0,1.79.43,1.79,1.29s-.79,1.25-2.37,1.25a26.08,26.08,0,0,1-2.93-.22,60.09,60.09,0,0,0-7.7-.62,28.61,28.61,0,0,0-5.77.44,13.7,13.7,0,0,1-2.63.4,1,1,0,0,1-1-.91L3,161.25l1.06-1a6.58,6.58,0,0,0,3.49-2.69,11.92,11.92,0,0,0,.86-5.28V114.75c0-2.6-.38-4.45-1.16-5.55A6.38,6.38,0,0,0,2.92,107a8,8,0,0,1-2.39-.67A1.17,1.17,0,0,1,0,105.2c0-.81.29-1.2.88-1.17a15.22,15.22,0,0,1,2,.35,20.49,20.49,0,0,0,4.42.33c1.36,0,3-.09,5.07-.28l2.67-.24c2.51-.22,4.43-.32,5.77-.32.81,0,1.21.34,1.21,1a1.93,1.93,0,0,1-.19,1,1.47,1.47,0,0,1-.91.39c-1.75.34-2.88.9-3.38,1.69s-.75,2.41-.75,4.84Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M71.71,103.09a20.88,20.88,0,0,1,11.91,3.39L86,108.1a2.34,2.34,0,0,0,1.31.51,1.18,1.18,0,0,0,1.1-.68l.77-1.32a.7.7,0,0,1,.66-.34c.75,0,1.13.92,1.13,2.76V116l.3,3.22.32,1.91a16.26,16.26,0,0,1,.22,2.51c0,1.07-.34,1.61-1,1.61-.39,0-.64-.34-.77-1a19.94,19.94,0,0,0-2-4.93,33,33,0,0,0-3.3-5.27q-6.36-8-14.15-8-6.91,0-11.23,6.59t-4.31,17.11q0,13.72,7.05,21.92,5.31,6.09,11.56,6.1a17.52,17.52,0,0,0,7.82-2.06A18.38,18.38,0,0,0,88,150.58l2.38-3a1.54,1.54,0,0,1,1-.72c.47,0,.69.28.69.93q0,1.76-2.88,5.56-7.56,9.94-18.66,9.94a21.69,21.69,0,0,1-17.44-8q-6.7-8-6.71-20.77a38.46,38.46,0,0,1,2.53-13.71A30.25,30.25,0,0,1,55.61,110,22.11,22.11,0,0,1,71.71,103.09Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M121.28,135.45l-4.77-13q-1.71-4.67-2.43-4.66c-.3,0-.83,1-1.61,3l-.69,1.78L107.51,134l-.39,1.06a2.81,2.81,0,0,0-.26,1.1c0,1,.87,1.44,2.63,1.44h10.77c.93,0,1.39-.29,1.39-.88a2.3,2.3,0,0,0-.18-.73l-.19-.46Zm-1.36-26.55,16.61,44.34a13.7,13.7,0,0,0,2.92,5,8.42,8.42,0,0,0,4.35,2.2c.44.09.66.49.66,1.21s-.28,1.16-.84,1.13a13.27,13.27,0,0,1-1.4-.26,40.6,40.6,0,0,0-9.07-.72,36.17,36.17,0,0,0-8.56.67,3.13,3.13,0,0,1-1,.18c-.68,0-1-.4-1-1.21,0-.41.26-.71.8-.91a6,6,0,0,0,2.91-2.13,5.32,5.32,0,0,0,1.15-3.22q0-2.38-3.17-10.45c-.66-1.64-1.38-2.58-2.17-2.82s-3.59-.36-8.4-.36h-5l-3.28.67c-.9.49-1.92,2.35-3.06,5.6l-1.13,3.26a8.1,8.1,0,0,0-.51,2.84q0,5.34,6.43,6.18c.93.15,1.37.56,1.35,1.26s-.49,1.12-1.32,1.12a9.68,9.68,0,0,1-1.86-.25,19.59,19.59,0,0,0-4.32-.55c-1.15,0-3,.14-5.56.42-1.91.22-3.09.34-3.55.34a.91.91,0,0,1-1-.81.61.61,0,0,1,0-.19,1.4,1.4,0,0,1,1-1.3,6.08,6.08,0,0,0,3.15-2.65,57.1,57.1,0,0,0,3.52-8.11l10-26.71q1.2-3.18,1.68-4.37l3.39-8.1,1.76-4v.13c.78-2.16,1.3-3.23,1.57-3.23s1.06,1.19,2,3.56Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M160.41,149q0,7.92,1.64,8.9a7.64,7.64,0,0,0,3.57.55q12.38,0,17.54-8.46c1.07-1.72,1.73-2.58,2-2.58.45,0,.67.58.67,1.75a16.19,16.19,0,0,1-3,9.63,8.45,8.45,0,0,1-7.27,3.74c-.64,0-1.79-.07-3.47-.21l-4.38-.32-4-.21c-1.34-.09-2.86-.13-4.57-.13a68.13,68.13,0,0,0-9.13.65,19.07,19.07,0,0,1-2,.2c-.71,0-1.06-.38-1.06-1.13,0-.52.32-.88,1-1.08a5,5,0,0,0,3.23-2.94,20.33,20.33,0,0,0,.9-7.14V114.74q0-6.76-4.86-7.66l-2.19-.43c-.92-.17-1.39-.6-1.39-1.29,0-.86.37-1.3,1.1-1.3a7.49,7.49,0,0,1,1.06.13,14.24,14.24,0,0,1,1.43.17l1.57.13c1.1.14,2.22.22,3.33.25a47.75,47.75,0,0,0,5.6-.51,51.3,51.3,0,0,1,5.81-.33c1.47,0,2.2.4,2.2,1.21s-.52,1.06-1.57,1.12q-3.69.3-3.69,5.89Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M214.29,135.45l-4.77-13c-1.14-3.11-2-4.66-2.44-4.66s-.83,1-1.6,3l-.7,1.78L200.52,134l-.4,1.06a3,3,0,0,0-.26,1.1c0,1,.88,1.44,2.63,1.44h10.78c.92,0,1.39-.29,1.39-.88a2.62,2.62,0,0,0-.19-.73l-.18-.46Zm-1.37-26.55,16.62,44.34a13.7,13.7,0,0,0,2.92,5,8.38,8.38,0,0,0,4.35,2.2c.44.09.65.49.65,1.21s-.28,1.16-.84,1.13a13.08,13.08,0,0,1-1.39-.26,40.6,40.6,0,0,0-9.07-.72,36.17,36.17,0,0,0-8.56.67,3.13,3.13,0,0,1-.95.18c-.68,0-1-.4-1-1.21,0-.41.27-.71.81-.91a6,6,0,0,0,2.9-2.13,5.23,5.23,0,0,0,1.15-3.22q0-2.38-3.17-10.45-1-2.46-2.16-2.82c-.8-.24-3.59-.36-8.4-.36h-5l-3.28.67c-.9.49-1.92,2.35-3.06,5.6l-1.13,3.26a8.1,8.1,0,0,0-.51,2.84q0,5.34,6.43,6.18c.92.15,1.37.56,1.35,1.26s-.49,1.12-1.32,1.12a9.68,9.68,0,0,1-1.86-.25,19.66,19.66,0,0,0-4.32-.55c-1.15,0-3,.14-5.57.42-1.9.22-3.08.34-3.55.34a.91.91,0,0,1-1-.81.61.61,0,0,1,0-.19,1.4,1.4,0,0,1,1-1.3,6.1,6.1,0,0,0,3.16-2.65,57.1,57.1,0,0,0,3.52-8.11l10-26.71q1.22-3.18,1.68-4.37l3.4-8.1,1.75-4v.13c.78-2.16,1.31-3.23,1.57-3.23s1.06,1.19,2,3.56Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M257.71,113.79,277,139l1.24,1.62c1.65,2.14,2.75,3.22,3.32,3.22s.78-.86.83-2.59q.33-9.36.33-17.85,0-10-1.73-13.49c-1.16-2.35-3.43-3.66-6.81-4-.81,0-1.21-.39-1.21-1a1,1,0,0,1,1.06-1.13,31.73,31.73,0,0,1,4.24.43,25.55,25.55,0,0,0,3.15.25,63.2,63.2,0,0,0,8-.8,9.47,9.47,0,0,1,1.06-.09c.78,0,1.17.4,1.17,1.21a1.23,1.23,0,0,1-1.17,1.29h0c-3.14.69-4.71,3.92-4.71,9.72V118l-.52,26.52-.15,3.65v7.93l.15,5.3c0,.8-.24,1.18-.74,1.15a2.07,2.07,0,0,1-1.35-.93L250,118.65c-.59-.74-1-1.1-1.21-1.1-.58,0-.87.63-.87,1.9v17.69q0,13.2,1.13,17.65,1.22,4.76,7.13,5.38c.72.09,1.09.45,1.09,1.09,0,.86-.38,1.3-1.13,1.3a8.6,8.6,0,0,1-1.43-.18,36.22,36.22,0,0,0-6.81-.59,31.71,31.71,0,0,0-6.59.61,11.54,11.54,0,0,1-1.86.24.89.89,0,0,1-1-1c0-.65.35-1,1.06-1.21a6.39,6.39,0,0,0,3.89-3.67,20.39,20.39,0,0,0,1.23-8l.25-10.08V120.25q0-13.05-8.29-13.72c-.75-.06-1.13-.42-1.13-1.08a1.08,1.08,0,0,1,.94-1.22,1,1,0,0,1,.3,0l2.53.13h1.54a54.32,54.32,0,0,0,6.11-.38,19,19,0,0,1,2-.13,2.61,2.61,0,0,1,1.78.57,28.4,28.4,0,0,1,2.77,3.5l2.52,3.44C257,112.69,257.55,113.51,257.71,113.79Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M320.46,103.09a21,21,0,0,1,11.91,3.39l2.41,1.62a2.37,2.37,0,0,0,1.32.51,1.18,1.18,0,0,0,1.09-.68l.77-1.32a.7.7,0,0,1,.66-.34c.75,0,1.13.92,1.13,2.76V116l.3,3.22.32,1.91a16.26,16.26,0,0,1,.22,2.51c0,1.07-.34,1.61-1,1.61-.39,0-.64-.34-.77-1a19.94,19.94,0,0,0-2-4.93,32.32,32.32,0,0,0-3.3-5.27q-6.37-8-14.15-8-6.92,0-11.23,6.59t-4.33,17.16q0,13.73,7.06,21.92,5.29,6.09,11.55,6.1a17.52,17.52,0,0,0,7.82-2.06,18.3,18.3,0,0,0,6.51-5.09l2.38-3a1.54,1.54,0,0,1,1-.72c.46,0,.7.28.7.93,0,1.17-1,3-2.89,5.56q-7.56,9.94-18.66,9.94a21.69,21.69,0,0,1-17.44-8q-6.7-8-6.71-20.77a38.68,38.68,0,0,1,2.53-13.71,30.13,30.13,0,0,1,6.7-10.9A22.11,22.11,0,0,1,320.46,103.09Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M380.83,133.28H363.52c-1.73,0-2.8.26-3.2.78s-.6,1.9-.6,4.14v10.55c0,4,.55,6.91,1.68,8.67s3,2.65,5.51,2.65c.83,0,1.24.37,1.24,1.13s-.63,1.34-1.9,1.34a25,25,0,0,1-2.59-.26,58.28,58.28,0,0,0-7.92-.6,52.34,52.34,0,0,0-6,.47c-1.49.2-2.42.3-2.81.3-.78,0-1.17-.48-1.17-1.43a.94.94,0,0,1,.88-1h0a4.7,4.7,0,0,0,3.56-2.64,15.22,15.22,0,0,0,1.08-6.57V113.1a6.3,6.3,0,0,0-1.45-4.56c-1-1-2.74-1.76-5.35-2.35a1.2,1.2,0,0,1-1-1.26c0-.83.39-1.25,1.17-1.25a17.85,17.85,0,0,1,2.41.31,38.44,38.44,0,0,0,6.94.54q1.35,0,5.77-.55a38.24,38.24,0,0,1,4-.3q1.32,0,1.32,1.17a1.14,1.14,0,0,1-1.06,1.17c-2.88.56-4.31,2.67-4.31,6.31v10.9c0,2.88.17,4.56.53,5.06s1.53.74,3.52.74h17.06c1.48,0,2.39-.35,2.72-1s.49-2.61.49-5.75v-9.13c0-3.62-1.72-5.81-5.15-6.54l-1.68-.38a1.17,1.17,0,0,1-1-1.26c0-.83.39-1.25,1.17-1.25a17.85,17.85,0,0,1,2.41.31,39.1,39.1,0,0,0,7,.54q1.38,0,5.77-.55a38.24,38.24,0,0,1,4-.3c.87,0,1.31.39,1.31,1.17a1.14,1.14,0,0,1-1.06,1.17c-2.87.56-4.31,2.67-4.31,6.31v36.42q0,6,1.66,8.67a5.88,5.88,0,0,0,5.43,2.65c.9,0,1.35.37,1.35,1.13s-.64,1.34-1.9,1.34a24.39,24.39,0,0,1-2.56-.26,58.28,58.28,0,0,0-7.92-.6,51.35,51.35,0,0,0-6.07.47c-1.48.2-2.42.3-2.81.3-.78,0-1.17-.48-1.17-1.43a1,1,0,0,1,.88-1h0a4.73,4.73,0,0,0,3.56-2.63,15.54,15.54,0,0,0,1.07-6.57V139.24c0-2.77-.16-4.44-.48-5S382.34,133.28,380.83,133.28Z"
      transform="translate(0 0.03)"
    />
    <Path
      d="M418.44,149l.29,4.24a8.53,8.53,0,0,0,2.14,5,5.88,5.88,0,0,0,4.43,1.74c1.2,0,1.79.43,1.79,1.29s-.79,1.25-2.37,1.25a25.9,25.9,0,0,1-2.92-.22,60.25,60.25,0,0,0-7.71-.62,29.35,29.35,0,0,0-5.77.44,13.7,13.7,0,0,1-2.63.4,1,1,0,0,1-1-.91l-.07-.34,1.05-1a6.58,6.58,0,0,0,3.49-2.69,11.92,11.92,0,0,0,.86-5.28V114.75q0-3.9-1.17-5.55a6.35,6.35,0,0,0-4.27-2.21,8,8,0,0,1-2.4-.67,1.17,1.17,0,0,1-.53-1.09c0-.81.29-1.2.88-1.17a15.22,15.22,0,0,1,2,.35,20.49,20.49,0,0,0,4.42.33c1.37,0,3-.09,5.08-.28l2.66-.24c2.51-.22,4.44-.32,5.77-.32.81,0,1.21.34,1.21,1a1.92,1.92,0,0,1-.18,1,1.5,1.5,0,0,1-.92.39c-1.75.34-2.88.9-3.38,1.69s-.74,2.41-.74,4.84Z"
      transform="translate(0 0.03)"
    />
    <Path3
      d="M.92,91.27H127.58V76.14s-9.74-21.28-7.69-38.64S131.17.5,131.17.5,144,19,144,40.83s-6.66,33.6-6.66,33.6V89.55h11.79V49.82l24.1-15.68h21l15.89,15.12s3.59,14,20,16.24S284,71.66,292.67,76.7A32,32,0,0,1,305,89.58H425"
      transform="translate(0 0.03)"
    />
    <Polygon points="162.47 66.09 169.63 66.09 169.63 54.33 161.94 54.33 162.47 66.09" />
    <Polyline points="174.25 90.17 174.25 71.13 185.03 71.13 185.03 90.17" />
  </Svg2>
)

const PathLogo = styled.path`
  fill: #832d2f;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
  fill-rule: evenodd;
`
const PathLogo2 = styled.path`
  fill: #832d2f;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
  fill-rule: evenodd;
`
const PathLogo3 = styled.path`
  fill: none;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
`

const PolygonLogo = styled.polygon`
  fill: none;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
`
const PolylineLogo = styled.polyline`
  fill: none;
  stroke: #832d2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.0615999698638916px;
`
export const LogoTop = () => (
  <Svg3 viewBox="0 0 229.85 80.65">
    <PathLogo d="M396.07,229Z" transform="translate(-86.72 -51.66)" />
    <PathLogo2
      d="M95.78,125.23l.16,2.09a4,4,0,0,0,1.15,2.46,3.34,3.34,0,0,0,2.39.86c.64,0,1,.21,1,.64s-.43.61-1.28.61a13.81,13.81,0,0,1-1.57-.11,36,36,0,0,0-4.15-.3,17.82,17.82,0,0,0-3.1.21,7.59,7.59,0,0,1-1.42.2.51.51,0,0,1-.53-.45l0-.16.57-.5a3.59,3.59,0,0,0,1.88-1.32,5.54,5.54,0,0,0,.46-2.6V108.4a4.53,4.53,0,0,0-.63-2.73,3.49,3.49,0,0,0-2.3-1.1,4.64,4.64,0,0,1-1.28-.33.57.57,0,0,1-.29-.54c0-.4.16-.59.47-.58a9.36,9.36,0,0,1,1.08.18,12.59,12.59,0,0,0,2.38.16c.73,0,1.61,0,2.73-.14l1.43-.12c1.35-.11,2.39-.15,3.11-.15.43,0,.65.16.65.49a.92.92,0,0,1-.1.49.88.88,0,0,1-.49.19,2.78,2.78,0,0,0-1.82.83,4.66,4.66,0,0,0-.41,2.38Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo2
      d="M125.35,102.66a12,12,0,0,1,6.41,1.67l1.29.8a1.44,1.44,0,0,0,.71.25.66.66,0,0,0,.59-.34l.41-.65a.38.38,0,0,1,.36-.16c.4,0,.61.45.61,1.35V109l.16,1.59.17.94a8.54,8.54,0,0,1,.12,1.23c0,.53-.18.79-.54.79-.21,0-.34-.16-.41-.49a9.2,9.2,0,0,0-1.08-2.42,16.18,16.18,0,0,0-1.78-2.6q-3.42-3.93-7.61-3.93a7.12,7.12,0,0,0-6,3.24,14,14,0,0,0-2.32,8.42q0,6.75,3.79,10.78a8.51,8.51,0,0,0,6.22,3,10.14,10.14,0,0,0,4.21-1,9.83,9.83,0,0,0,3.5-2.5l1.28-1.48a.84.84,0,0,1,.54-.35c.25,0,.37.13.37.45a5.47,5.47,0,0,1-1.55,2.74,12.57,12.57,0,0,1-10,4.89,12.15,12.15,0,0,1-9.38-3.94,14.5,14.5,0,0,1-3.61-10.22,17.45,17.45,0,0,1,1.36-6.74,14.77,14.77,0,0,1,3.59-5.34A12.47,12.47,0,0,1,125.35,102.66Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo2
      d="M152,118.58l-2.57-6.39c-.61-1.53-1-2.3-1.3-2.3s-.45.5-.87,1.48l-.37.88-2.3,5.6-.21.52a1.27,1.27,0,0,0-.14.54c0,.49.47.71,1.42.71h5.79c.5,0,.75-.14.75-.43a1.07,1.07,0,0,0-.1-.36l-.1-.23Zm-.73-13.06,8.94,21.81a6.43,6.43,0,0,0,1.57,2.46,4.61,4.61,0,0,0,2.34,1.09c.23,0,.35.24.35.59s-.15.57-.45.56a6.54,6.54,0,0,1-.75-.13,24.2,24.2,0,0,0-4.88-.35,20.93,20.93,0,0,0-4.61.33,1.75,1.75,0,0,1-.51.08c-.37,0-.54-.19-.54-.59,0-.2.14-.35.43-.45a3.18,3.18,0,0,0,1.57-1,2.47,2.47,0,0,0,.62-1.58,18.18,18.18,0,0,0-1.71-5.14c-.35-.81-.74-1.27-1.17-1.39a28.87,28.87,0,0,0-4.52-.18h-2.66l-1.77.33c-.48.24-1,1.16-1.64,2.76l-.61,1.6a3.75,3.75,0,0,0-.27,1.4q0,2.62,3.46,3c.5.07.73.28.72.62s-.26.55-.71.55a5.58,5.58,0,0,1-1-.12,11.31,11.31,0,0,0-2.32-.27c-.62,0-1.62.07-3,.2-1,.11-1.66.17-1.91.17a.47.47,0,0,1-.53-.4.14.14,0,0,1,0-.09.69.69,0,0,1,.53-.64,3.19,3.19,0,0,0,1.7-1.3,26.88,26.88,0,0,0,1.89-4l5.38-13.15c.43-1,.74-1.75.91-2.15l1.82-4,.95-2v.07c.42-1.07.7-1.59.84-1.59s.57.58,1.08,1.75Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo2
      d="M173.08,125.23c0,2.6.29,4.06.88,4.38a4.46,4.46,0,0,0,1.92.27q6.66,0,9.44-4.16c.57-.85.93-1.27,1.07-1.27s.36.28.36.86a7.49,7.49,0,0,1-1.61,4.74,4.71,4.71,0,0,1-3.91,1.84c-.35,0-1,0-1.87-.11l-2.36-.15-2.15-.11q-1.08-.06-2.46-.06a38.43,38.43,0,0,0-4.91.32c-.36.05-.72.08-1.08.1a.5.5,0,0,1-.57-.56c0-.26.17-.43.51-.53a2.61,2.61,0,0,0,1.74-1.45,9.31,9.31,0,0,0,.49-3.51V108.39c0-2.21-.88-3.47-2.62-3.77l-1.18-.21c-.49-.08-.75-.29-.75-.63s.2-.64.6-.64a5.51,5.51,0,0,1,.57.06c.18,0,.44,0,.77.09l.84.06a17.93,17.93,0,0,0,1.79.12,27.73,27.73,0,0,0,3-.25,29.5,29.5,0,0,1,3.12-.16c.79,0,1.19.2,1.19.6s-.28.52-.85.55c-1.32.09-2,1.06-2,2.89Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo2
      d="M202.07,118.58l-2.57-6.39c-.61-1.53-1.08-2.3-1.31-2.3s-.45.5-.86,1.48l-.38.88-2.29,5.6-.22.52a1.49,1.49,0,0,0-.14.54c0,.49.48.71,1.42.71h5.8c.49,0,.75-.14.75-.43a1.08,1.08,0,0,0-.11-.36l-.09-.23Zm-.74-13.06,8.94,21.81a6.55,6.55,0,0,0,1.57,2.46,4.69,4.69,0,0,0,2.35,1.09c.23,0,.34.24.34.59s-.15.57-.45.56a6.54,6.54,0,0,1-.75-.13,24.09,24.09,0,0,0-4.88-.35,20.84,20.84,0,0,0-4.6.33,1.81,1.81,0,0,1-.51.08c-.37,0-.54-.19-.54-.59,0-.2.14-.35.43-.45a3.22,3.22,0,0,0,1.57-1,2.41,2.41,0,0,0,.61-1.58,18,18,0,0,0-1.7-5.14c-.36-.81-.75-1.27-1.16-1.39a29.06,29.06,0,0,0-4.52-.18h-2.69l-1.77.33q-.72.36-1.65,2.76l-.6,1.6a3.76,3.76,0,0,0-.28,1.4c0,1.75,1.16,2.76,3.46,3,.5.07.74.28.73.62s-.27.55-.71.55a5.58,5.58,0,0,1-1-.12,11.32,11.32,0,0,0-2.33-.27c-.62,0-1.61.07-3,.2-1,.11-1.66.17-1.91.17a.48.48,0,0,1-.54-.4.14.14,0,0,1,0-.09.69.69,0,0,1,.54-.64,3.27,3.27,0,0,0,1.7-1.3,27.92,27.92,0,0,0,1.89-4l5.38-13.15c.43-1,.74-1.75.9-2.15l1.83-4,.95-2v.07c.42-1.07.7-1.59.84-1.59s.57.58,1.08,1.75Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo2
      d="M225.43,107.93l10.4,12.4.66.8c.89,1.05,1.48,1.59,1.79,1.59s.42-.43.45-1.28c.12-3.07.17-6,.17-8.78q0-4.92-.93-6.64c-.62-1.15-1.84-1.8-3.66-1.94-.44,0-.65-.19-.65-.49a.53.53,0,0,1,.57-.56,21.7,21.7,0,0,1,2.28.21,13.75,13.75,0,0,0,1.7.13,36.36,36.36,0,0,0,4.3-.4,5.46,5.46,0,0,1,.57,0c.42,0,.63.19.63.59a.63.63,0,0,1-.63.64h0q-2.54.51-2.53,4.78v1l-.28,13-.08,1.79v3.9l.08,2.61c0,.39-.13.58-.4.57a1.14,1.14,0,0,1-.73-.46l-17.87-21.12c-.32-.37-.54-.54-.65-.54-.32,0-.47.31-.47.93v8.7a39.13,39.13,0,0,0,.61,8.69q.65,2.34,3.83,2.64c.39,0,.59.23.59.54a.56.56,0,0,1-.61.64,4.91,4.91,0,0,1-.77-.09,21.42,21.42,0,0,0-3.66-.29,19.19,19.19,0,0,0-3.55.3,5.58,5.58,0,0,1-1,.12c-.36,0-.54-.17-.54-.49s.19-.49.57-.6a3.28,3.28,0,0,0,2.09-1.8,9.21,9.21,0,0,0,.67-3.94l.13-5V111.1q0-6.42-4.46-6.75c-.4,0-.61-.2-.61-.53a.56.56,0,0,1,.51-.6.86.86,0,0,1,.16,0l1.36.07h.83a32.81,32.81,0,0,0,3.29-.19,9.46,9.46,0,0,1,1.07-.06,1.47,1.47,0,0,1,1,.28,12.76,12.76,0,0,1,1.49,1.72l1.36,1.69C225,107.38,225.34,107.79,225.43,107.93Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo2
      d="M259.2,102.66a12,12,0,0,1,6.4,1.67l1.3.8a1.44,1.44,0,0,0,.71.25.66.66,0,0,0,.59-.34l.41-.65a.38.38,0,0,1,.36-.16c.4,0,.61.45.61,1.35V109l.16,1.59.17.94a8.54,8.54,0,0,1,.12,1.23c0,.53-.19.79-.54.79s-.34-.16-.41-.49a9.2,9.2,0,0,0-1.08-2.42,15.16,15.16,0,0,0-1.78-2.6q-3.42-3.93-7.61-3.93a7.11,7.11,0,0,0-6,3.24,14.16,14.16,0,0,0-2.33,8.44q0,6.75,3.8,10.79a8.49,8.49,0,0,0,6.21,3,10.14,10.14,0,0,0,4.21-1,9.86,9.86,0,0,0,3.5-2.51l1.28-1.48a.81.81,0,0,1,.54-.35c.25,0,.38.14.38.46,0,.57-.54,1.47-1.56,2.73a12.57,12.57,0,0,1-10,4.89,12.12,12.12,0,0,1-9.38-3.93,14.52,14.52,0,0,1-3.61-10.22,17.5,17.5,0,0,1,1.36-6.75,14.79,14.79,0,0,1,3.6-5.36A12.47,12.47,0,0,1,259.2,102.66Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo2
      d="M291.68,117.51h-9.31c-.93,0-1.51.13-1.73.39a3.88,3.88,0,0,0-.32,2v5.19a7.61,7.61,0,0,0,.91,4.26,3.33,3.33,0,0,0,3,1.31c.45,0,.67.18.67.55s-.34.66-1,.66a13,13,0,0,1-1.4-.13,33.62,33.62,0,0,0-4.26-.29,29.05,29.05,0,0,0-3.23.23c-.8.1-1.3.15-1.51.15-.42,0-.63-.24-.63-.71a.48.48,0,0,1,.47-.49h0a2.51,2.51,0,0,0,1.91-1.3,6.93,6.93,0,0,0,.59-3.23V107.59a3,3,0,0,0-.78-2.25,6.26,6.26,0,0,0-2.88-1.15.61.61,0,0,1-.54-.62.55.55,0,0,1,.63-.62c.43,0,.87.08,1.3.15a23.24,23.24,0,0,0,3.73.27c.48,0,1.52-.09,3.1-.27q1.08-.12,2.16-.15c.47,0,.71.19.71.58a.58.58,0,0,1-.57.57c-1.55.28-2.32,1.32-2.32,3.11v5.36a5.86,5.86,0,0,0,.28,2.49c.2.25.83.36,1.9.36h9.18c.79,0,1.28-.17,1.46-.49a8.36,8.36,0,0,0,.26-2.83v-4.49a3,3,0,0,0-2.77-3.22l-.9-.18a.6.6,0,0,1-.54-.62.55.55,0,0,1,.63-.62c.44,0,.87.08,1.3.15a23.61,23.61,0,0,0,3.76.27c.5,0,1.53-.09,3.11-.27.71-.08,1.43-.13,2.15-.15.47,0,.71.19.71.58a.58.58,0,0,1-.57.57c-1.55.28-2.32,1.32-2.32,3.11v17.92a7.75,7.75,0,0,0,.89,4.26,3.23,3.23,0,0,0,2.92,1.3c.49,0,.73.19.73.56s-.35.66-1,.66a12.41,12.41,0,0,1-1.38-.13,35.72,35.72,0,0,0-4.26-.29,29.6,29.6,0,0,0-3.27.23c-.8.1-1.3.15-1.51.15-.42,0-.63-.24-.63-.71a.48.48,0,0,1,.47-.49h0a2.56,2.56,0,0,0,1.92-1.29,7.09,7.09,0,0,0,.57-3.24v-5.71a6.56,6.56,0,0,0-.25-2.46C293,117.71,292.49,117.51,291.68,117.51Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo2
      d="M311.92,125.23l.15,2.09a4,4,0,0,0,1.16,2.46,3.37,3.37,0,0,0,2.38.85c.65,0,1,.21,1,.64s-.42.61-1.27.61a13.65,13.65,0,0,1-1.57-.11,36,36,0,0,0-4.15-.3,18,18,0,0,0-3.11.21,7.49,7.49,0,0,1-1.41.2.52.52,0,0,1-.54-.45l0-.16.57-.5a3.53,3.53,0,0,0,1.87-1.32,5.42,5.42,0,0,0,.47-2.6V108.4a4.53,4.53,0,0,0-.63-2.73,3.5,3.5,0,0,0-2.3-1.09,4.71,4.71,0,0,1-1.29-.33.56.56,0,0,1-.29-.54c0-.39.16-.59.48-.57a7.69,7.69,0,0,1,1.07.17,11.86,11.86,0,0,0,2.38.16c.74,0,1.62,0,2.74-.13l1.43-.12c1.35-.11,2.39-.16,3.1-.16.44,0,.65.17.65.49a.54.54,0,0,1-.59.69,2.73,2.73,0,0,0-1.82.83,4.66,4.66,0,0,0-.4,2.38Z"
      transform="translate(-86.72 -51.66)"
    />
    <PathLogo3
      d="M87.26,96.85h68.15V89.4s-5.24-10.47-4.14-19,6.07-18.2,6.07-18.2,6.9,9.1,6.9,19.84-3.58,16.53-3.58,16.53V96H167V76.45l13-7.71h11.3l8.55,7.44s1.93,6.89,10.76,8,29,3,33.66,5.51A16.45,16.45,0,0,1,250.87,96h64.57"
      transform="translate(-86.72 -51.66)"
    />
    <PolygonLogo points="87.45 32.79 91.31 32.79 91.31 27 87.17 27 87.45 32.79" />
    <PolylineLogo points="93.8 44.63 93.8 35.27 99.59 35.27 99.59 44.63" />
  </Svg3>
)
